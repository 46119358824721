import type { CountryCode, EIN, EINDash, FundType, Letter, USStateCode, UUID } from '@endaoment-frontend/types';

import { makeRoute } from './makeRoute';
import { formatEinOrUuid } from './utils';
import type { SearchParamsUsedByWizards } from './wizardUtils';
import { assembleSearchParamsForWizards } from './wizardUtils';

export const dashboard = makeRoute((args?: { wizardParams?: SearchParamsUsedByWizards }) =>
  assembleSearchParamsForWizards(`/`, args?.wizardParams ? args.wizardParams : {}),
);
type ExploreSearchParams = {
  searchTerm?: string;
  codes?: Array<Letter>;
  countries?: Array<CountryCode>;
  states?: Array<USStateCode>;
  type?: Array<FundType>;
};
export const explore = makeRoute((args: ExploreSearchParams) => {
  const queryParts: Array<string> = [];
  if (args.searchTerm) queryParts.push(`searchTerm=${args.searchTerm}`);
  if (args.codes) queryParts.push(`codes=${args.codes.join(',')}`);
  if (args.countries) queryParts.push(`countries=${args.countries.join(',')}`);
  if (args.states) queryParts.push(`states=${args.states.join(',')}`);
  if (args.type) queryParts.push(`type=${args.type.join(',')}`);

  return `/explore${queryParts.length > 0 ? `?${queryParts.join('&')}` : ''}`;
});
export const fund = makeRoute(
  (
    args: { wizardParams?: SearchParamsUsedByWizards } & (
      | { id: UUID; vanityUrl?: null | undefined }
      | { id?: UUID; vanityUrl: string }
    ),
  ) => {
    if (!args.wizardParams) return args.vanityUrl ? `/${args.vanityUrl}` : `/funds/${args.id}`;
    if (args.vanityUrl) return assembleSearchParamsForWizards(`/${args.vanityUrl}`, args.wizardParams);
    return assembleSearchParamsForWizards(`/funds/${args.id}`, args.wizardParams);
  },
);
export const fundCreate = makeRoute(() => `/funds/create`);

export const org = makeRoute((args: { einOrId: EIN | EINDash | UUID; wizardParams?: SearchParamsUsedByWizards }) => {
  if (!args.wizardParams) return `/orgs/${formatEinOrUuid(args.einOrId)}`;
  return assembleSearchParamsForWizards(`/orgs/${formatEinOrUuid(args.einOrId)}`, args.wizardParams);
});
export const orgClaim = makeRoute(
  (args: { einOrId?: EIN | UUID } | undefined) => `/orgs/claim${args?.einOrId ? `?einOrId=${args.einOrId}` : ''}`,
);
export const batchDeploy = makeRoute(() => `/orgs/batch-deploy`);
export const subproject = makeRoute(
  (args: { sponsorEinOrId: EIN | EINDash | UUID; id: UUID; wizardParams?: SearchParamsUsedByWizards }) => {
    const base = `/orgs/${formatEinOrUuid(args.sponsorEinOrId)}/${args.id}`;
    if (!args.wizardParams) return base;
    return assembleSearchParamsForWizards(base, args.wizardParams);
  },
);
export const portfolios = makeRoute(
  (args: {
    categoryIds?: Array<UUID>;
    chainIds?: Array<number>;
    view?: 'portfolios' | 'positions' | 'target-allocations';
  }) => {
    const urlParams = new URLSearchParams(undefined);
    if (args.view) {
      urlParams.set('view', args.view);
    }
    if (args.chainIds) {
      urlParams.set('filterChainIds', args.chainIds.map(id => id.toString()).join(','));
    }
    if (args.categoryIds) {
      urlParams.set('filterCategoryIds', args.categoryIds.map(id => id.toString()).join(','));
    }

    const paramsStr = urlParams.size > 0 ? `?${urlParams.toString()}` : '';
    if (args.chainIds || args.categoryIds) {
      return `/portfolios${paramsStr}#filter`;
    }

    return `/portfolios${paramsStr}`;
  },
);
export const portfolio = makeRoute((args: { id: UUID }) => `/portfolios/${args.id}`);
export const stats = makeRoute(() => `/stats`);
export const _404 = makeRoute(() => `/404`);
export const transaction = makeRoute(
  (args: { txHash: string; chainId: number }) => `/tx/${args.txHash}?chainId=${args.chainId}`,
);
export const address = makeRoute(
  (args: { address: string; chainId: number }) => `/address/${args.address}?chainId=${args.chainId}`,
);
export const refer = makeRoute((args: { code: string }) => `/refer/${args.code}`);
