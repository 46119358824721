import { z } from 'zod';

import { RequestHandler } from '@endaoment-frontend/data-fetching';
import type {
  Activity,
  Address,
  ContinentName,
  CountryCode,
  CreateWalletClaimInput,
  CreateWireClaimInput,
  EIN,
  EINDash,
  Org,
  OrgClaim,
  OrgListing,
  Subproject,
  SubprojectListing,
  UUID,
} from '@endaoment-frontend/types';
import {
  activitySchema,
  arraySchemaInvalidsFiltered,
  bigIntSchema,
  einSchema,
  isUuid,
  orgClaimSchema,
  orgListingSchema,
  orgSchema,
  subprojectListingSchema,
  subprojectSchema,
  uuidSchema,
} from '@endaoment-frontend/types';
import { isValidEinFormat, normalizeEin } from '@endaoment-frontend/utils';

const orgIdentifiersSchema = z.object({ id: uuidSchema, ein: einSchema.nullish() });
type OrgIdentifiers = z.infer<typeof orgIdentifiersSchema>;
/**
 * Fetch an array of Org EINs for pregenerating pages
 */
export const PrerenderOrgs = new RequestHandler('PrerenderOrgs', fetch => async (): Promise<Array<OrgIdentifiers>> => {
  const PREGEN_OBFUSCATION_CODE = '03787724-7b08-4e2b-a8e0-5ae063c9a31a';
  try {
    const res = await fetch(`/v1/orgs/pregen/${PREGEN_OBFUSCATION_CODE}`);
    const orgs = z.array(orgIdentifiersSchema).parse(res);
    // TODO: Update once backend accepts count
    return orgs.slice(0, 20);
  } catch {
    // Prerendering is not critical, so we can just return an empty array if it fails
    return [];
  }
});

/**
 * Fetch an array of our most recently deployed Orgs
 */
export const GetRecentOrgs = new RequestHandler(
  'GetRecentOrgs',
  fetch => async (): Promise<{ claimed: Array<OrgListing>; deployed: Array<OrgListing> }> => {
    const res = await fetch('/v1/orgs/recent');
    return z
      .object({
        claimed: arraySchemaInvalidsFiltered(orgListingSchema),
        deployed: arraySchemaInvalidsFiltered(orgListingSchema),
      })
      .parse(res);
  },
  {
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/orgs/recent` }),
  },
);

/**
 * Fetch an array of our most featured Orgs
 */
export const GetFeaturedOrgs = new RequestHandler(
  'GetFeaturedOrgs',
  fetch =>
    async (count: number = 9): Promise<Array<Org>> => {
      const res = await fetch('/v1/orgs/featured');
      return arraySchemaInvalidsFiltered(orgSchema).parse(res).slice(0, count);
    },
  {
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/orgs/featured` }),
  },
);

/**
 * Fetch an Org uses either its EIN or ID, whichever is provided
 */
export const GetOrg = new RequestHandler(
  'GetOrg',
  fetch =>
    async (einOrId: EIN | EINDash | UUID): Promise<Org> => {
      if (isValidEinFormat(einOrId)) {
        return orgSchema.parse(await fetch(`/v1/orgs/ein/${normalizeEin(einOrId)}`));
      }

      if (isUuid(einOrId)) {
        return orgSchema.parse(await fetch(`/v1/orgs/${einOrId}`));
      }

      throw new Error('Invalid Org ID or EIN');
    },
  {
    augmentArgs: ([einOrId]): [EIN | UUID] => {
      if (isValidEinFormat(einOrId)) {
        return [normalizeEin(einOrId)];
      }

      return [einOrId];
    },
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/orgs/:id`, ein: `${baseURL}/v1/orgs/ein/:ein` }),
  },
);

/**
 * Mutation that needs to be called after an Org or batch of Orgs is deployed to the blockchain
 */
export const RegisterOrgDeployment = new RequestHandler(
  'RegisterOrgDeployment',
  fetch =>
    async (transactionHash: Address, chainId: number): Promise<Array<Org>> => {
      const res = await fetch('/v2/orgs/process-deployment', {
        method: 'POST',
        body: {
          deploymentTransactionHash: transactionHash,
          chainId,
        },
      });
      return z.array(orgSchema).parse(res);
    },
);

/**
 * Mutation for a User filing a claim for an Org
 */
export const CreateOrgClaim = new RequestHandler(
  'CreateOrgClaim',
  fetch =>
    async (
      orgId: UUID,
      input: (CreateWalletClaimInput & { type: 'wallet' }) | (CreateWireClaimInput & { type: 'wire' }),
    ): Promise<{ id: UUID }> => {
      if (input.type === 'wallet') {
        // NOTE: This gross unrolling needs to occur so that the API can receive a flat object as opposed to a nested one with the same properties
        const unrolledInput = {
          ...input,
          claimantAddressLine1: input.claimantAddress.line1,
          claimantAddressLine2: input.claimantAddress.line2,
          claimantAddressCity: input.claimantAddress.city,
          claimantAddressState: input.claimantAddress.state,
          claimantAddressPostalCode: input.claimantAddress.zip,
          claimantAddressCountry: input.claimantAddress.country,
        };

        const res = await fetch(`/v1/claims/wallet/${orgId}`, { method: 'POST', body: unrolledInput });
        return z.object({ id: uuidSchema }).parse(res);
      }

      // NOTE: This gross unrolling needs to occur so that the API can receive a flat object as opposed to a nested one with the same properties
      const unrolledInput = {
        ...input,
        wireBillingLine1: input.wireBillingAddress.line1,
        wireBillingLine2: input.wireBillingAddress.line2,
        wireBillingCity: input.wireBillingAddress.city,
        wireBillingState: input.wireBillingAddress.state,
        wireBillingPostalCode: input.wireBillingAddress.zip,
        bankAddressLine1: input.bankAddress.line1,
        bankAddressLine2: input.bankAddress.line2,
        bankAddressCity: input.bankAddress.city,
        bankAddressState: input.bankAddress.state,
        bankAddressPostalCode: input.bankAddress.zip,
        accountNumber: input.bankAccountNumber,
      };
      const res = await fetch(`/v1/claims/wire/${orgId}`, { method: 'POST', body: unrolledInput });
      return z.object({ id: uuidSchema }).parse(res);
    },
);

/**
 * Fetch recent activity associated with a Org
 */
export const GetOrgActivity = new RequestHandler(
  'GetOrgActivity',
  fetch =>
    async (id: UUID): Promise<Array<Activity>> => {
      const res = await fetch(`/v1/activity/org/${id}`);
      return arraySchemaInvalidsFiltered(activitySchema).parse(res);
    },
  {
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/activity/org/:id` }),
  },
);

/**
 * Fetch a list of Subprojects for a given Org EIN or ID
 */
export const GetSubprojectsForOrg = new RequestHandler(
  'GetSubprojectsForOrg',
  fetch =>
    async (einOrId: EIN | EINDash | UUID): Promise<Array<SubprojectListing>> => {
      let res;
      if (isUuid(einOrId)) {
        res = await fetch(`/v1/orgs/${einOrId}/subprojects`);
      } else {
        res = await fetch(`/v1/orgs/ein/${normalizeEin(einOrId)}/subprojects`);
      }

      return arraySchemaInvalidsFiltered(subprojectListingSchema).parse(res);
    },
  {
    makeMockEndpoints: ({ baseURL }) => ({
      default: `${baseURL}/v1/orgs/:id/subprojects`,
      ein: `${baseURL}/v1/orgs/ein/:ein/subprojects`,
    }),
  },
);

/**
 * Fetch a Subproject using its id (primary key)
 */
export const GetSubproject = new RequestHandler(
  'GetSubproject',
  fetch =>
    async (subprojectId: UUID): Promise<Subproject> => {
      const res = await fetch(`/v1/subprojects/${subprojectId}`);
      return subprojectSchema.parse(res);
    },
  {
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/subprojects/:id` }),
  },
);

export const GetAdminClaims = new RequestHandler(
  'GetAdminClaims',
  fetch =>
    async (count?: number, offset?: number, orgSearchTerm?: string): Promise<Array<OrgClaim>> => {
      const res = await fetch(`/v1/claims`, {
        params: {
          count,
          offset,
          orgSearchTerm,
        },
      });
      return z.array(orgClaimSchema).parse(res);
    },
);

export const GetAdminPendingClaims = new RequestHandler(
  'GetPendingClaims',
  fetch =>
    async (count?: number, offset?: number, orgSearchTerm?: string): Promise<Array<OrgClaim>> => {
      const res = await fetch('/v1/claims/pending', {
        params: {
          count,
          offset,
          orgSearchTerm,
        },
      });
      return z.array(orgClaimSchema).parse(res);
    },
);

const claimBankInfoSchema = z.object({
  name: z.string(),
  description: z.string(),
});
type BankInfo = z.infer<typeof claimBankInfoSchema>;
export const GetClaimBankInfo = new RequestHandler(
  'GetClaimBankInfo',
  fetch =>
    async (claimId: UUID): Promise<BankInfo> =>
      claimBankInfoSchema.parse(await fetch(`/v1/claims/${claimId}/bank-info`)),
);

export const ApproveWireClaim = new RequestHandler(
  'ApproveWireClaim',
  fetch =>
    async (claimId: UUID): Promise<void> => {
      fetch(`/v1/claims/${claimId}/approve-wire`, { method: 'POST' });
    },
);

export const ApproveWalletClaim = new RequestHandler('ApproveWalletClaim', fetch => async (claimId: UUID) => {
  // TODO: Ensure this is the correct endpoint
  fetch(`/v2/claims/${claimId}/approve-wallet`, {
    method: 'POST',
  });
});

export const RejectClaim = new RequestHandler('RejectClaim', fetch => async (claimId: UUID) => {
  fetch(`/v1/claims/${claimId}/reject`, { method: 'POST' });
});

// TODO: Figure out how to properly set up mapping for this
const onboardedCountsSchema = z.object({
  continents: z.record(z.number()),
  countries: z.record(z.number()),
});
type OnboardedCounts = {
  continents: Record<ContinentName, number>;
  countries: Record<CountryCode, number>;
};

/**
 * Fetch the number of onboarded Orgs by continent and country
 */
export const GetOnboardedCounts = new RequestHandler(
  'GetOnboardedCounts',
  fetch => async (): Promise<OnboardedCounts> => {
    const res = await fetch('/v1/orgs/deployed/geocount');
    return onboardedCountsSchema.parse(res);
  },
  {
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/orgs/deployed/geocount` }),
  },
);

const orgEligibleDistributionSchema = z.object({
  eligibleForAmount: bigIntSchema,
  source: z.object({
    id: uuidSchema,
    name: z.string(),
  }),
  distributionTimestampUtc: z.number(),
});
type OrgEligibleDistribution = z.infer<typeof orgEligibleDistributionSchema>;

/**
 * Fetch the distributions from Impact Funds that an Org will receive
 */
export const GetOrgImpactDistributions = new RequestHandler(
  'GetOrgImpactDistributions',
  fetch =>
    async (orgId: UUID): Promise<Array<OrgEligibleDistribution>> => {
      const res = await fetch(`v1/orgs/${orgId}/distributions`);
      const { distributions } = z
        .object({
          distributions: z.array(orgEligibleDistributionSchema),
        })
        .parse(res);
      return distributions;
    },
  { makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/orgs/:id/distributions` }) },
);
